@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
/*this index file contains the general settings for the 
overall webpage, so for each section(about me, portfolio,
contact, etc.) it gives general settings of spacing. 
Additionally, it creates overall format classes(as seen below
in the :root and others that are referred to on all the different
jsx and css pages. think of this page of creating global format 
templates that are used throughout the entire project*/
*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

/*container class general settings
below that we will wrap around each section*/
:root{
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77,181,255,0.4);
    --color-white: #fff;
    --color-light: rgba(255,255,255,0.6);

    --container-width-lg: 75%;
    --container-width-md :86%;
    --container-width-sm: 90%;

    --transition: all 400ms ease;
}

html{
    scroll-behavior: smooth;
}

::-webkit-scrollbar{
    display: none;
}

body{
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    /*background-image: url(../src/assets/bg.png);*/

}

/*  GENERAL Style, this is for all the general classes */
.container{
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5{
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

/* general height and width measurements for all the 5 sections
(about, portfolio, contact, services, etc.)*/
section{
    /*margin-top: 8rem;*/
    margin-top: 5rem;
    padding-top: 1.5rem;
}

/*for the top of each section, the text will be formatted this way*/
section > h2, section > h5{
    text-align: center;
    color: var(--color-light); 
}

section > h2{
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light{
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);

}
/*this is when the socials on the side will change to white
when the mouse hovers over it*/
a:hover{
    color:var(--color-white);
}

.btn{
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
    border-radius: 0.4rem;
}

.btn:hover{
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary{
    background: var(--color-primary);
    color: var(--color-bg);
}

img{
    display: block;
    width: 100%;
    object-fit: cover;
}

/* ------ below is for media devices like tablets*/
@media screen and (max-width: 1024px) {
    .container{
        width: var(--container-width-md);
    }

    section{
        margin-top: 6rem;
    }
}

/* ------ below is for media devices like tablets*/
@media screen and (max-width: 600px) {
    .container{
        width: var(--container-width-sm);
    }

    section > h2{
        margin-top: 2rem;
    }
}