/*this class was created on the jsx page. this section
below formats the content in each 'experience card' */
.experience__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}
/*this section here creates the card background for both sections*/
.experience__container > div{
    background: var(--color-bg-variant);
    padding: 2.4rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    /*not sure why transition goes in this area but not in the 
    below section where the transition animation is created*/
    transition: var(--transition);
}

/*when the mouse hovers over the two cards, this causes it
to have an effect on the display*/
.experience__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}
/*this is for the header of each of those cards, the
'financial tools' and 'software development'*/
.experience__container > div h3{
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

/*formats the spacing between each line item in the cards(ex:
python experienced, javascript experienced etc...)*/
.experience__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}

.experience__details {
    display: flex;
    gap: 1rem;
}

/*this class is created on the jsx page, this makes the checkmarks blue lol*/
.experience__details-icon{
    margin-top: 6px;
    color: var(--color-primary);
}

/*----below is for media devices like tablets*/
@media screen and (max-width: 1024px) {
    .experience__container{
        grid-template-columns: 1fr;
    }

    /*so in the experience_container class, this section goes 
    to the direct divs in it(not all the divs, just one level down,
    and applies these settings. so this will apply to the two divs 
    that represent each of the two cards of financial tools and 
    software dev*/
    .experience_container > div{
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .experience__content{
        padding: 1rem;
    }
}

/* ------ below is for media devices like small devices*/
@media screen and (max-width: 600px) {
    .experience__container{
        gap: 1rem;
    }

    .experience__container > div{
        width: 100%;
        padding: 2rem 1rem;
    }
}