
#header {
    margin-top: 2rem;

}


    /*
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
    
    margin-top: 2rem;*/


/* i believe this is for the text in the header(like the title, nikdesh
financial anaylst etc.*/
.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}


/* CTA, this below is for 'download cv' and lets talk button */

.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/*this is for formating the header socials(github, linkedin, etc.)*/
.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;

    /*
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;*/
    
}

.header__socials::after{
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);

    
}

/*below is for the image of me at the top of the website to format it
in its size and border and shape etc.*/

.me {
    /*background: linear-gradient(var(--color-primary), transparent);*/
    width: 32rem;
    height: 32rem;
    /*position: inherit;*/
    position: inherit;
    left: calc(50% - 16rem);
    
    /*I guess whatever part of the picture isn't in the border-radius
    calculation is hidden based on the overflow line below*/
    /*border-radius: 12rem 12rem 0 0;*/
    
    /*zero should 5rem but it doesn't look good for some reason,
    i have a bug there*/
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
}

/* Scroll down button formatting)*/

/*
.scroll__down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}
*/
/* not sure why we need to do this here and in index.css*/

/*----below is for media devices like tablets*/

/*@media screen and (max-width: 1024px) {
    header{
        height: 68vh;
    }
    
    .me {
        width: 22rem;
        height: 22rem;
        left: calc(50% - 11rem);
    }
}*/

/* ------ below is for media devices like small devices*/

/*@media screen and (max-width: 600px) {
    /*
    header{
        height: 100vh;
    }
    
    .header__socials,.scroll__down{
        display: none;
    }
    
    .me {
        width: 22rem;
        height: 22rem;
        left: calc(50% - 11rem);
    }
    

}*/

/* === MEDIA QUERIES (SMALL DEVICES) === */
@media screen and (max-width: 600px) {
    .me {
        width: 22rem;
        height: 22rem;
        left: calc(50% - 11rem);
    }
}